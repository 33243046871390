<template>
    <LiefengContent>
        <template v-slot:title>答题记录</template>
        <template v-slot:toolsbarLeft>
            <Input style="width: 200px" v-model="account" placeholder="请输入联系电话" />
            <Button
                type="info"
                @click="
                    () => {
                        getList()
                    }
                "
            >
                查询
            </Button>
            <Button
                type="default"
                @click="
                    () => {
                        restData()
                    }
                "
            >
                清空条件
            </Button>
        </template>
        <template v-slot:contentArea>
            <div class="leftMenu">
                <LiefengTable
                    :talbeColumns="tableColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="onSelect"
                ></LiefengTable>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    data() {
        return {
            selectList: [
                {
                    dictValue: "浏览",
                    dictKey: "0",
                },
                {
                    dictValue: "拨打电话",
                    dictKey: "1",
                },
                {
                    dictValue: "定位",
                    dictKey: "2",
                },
            ],
            tableData: [],
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            tableColumns: [
                {
                    title: "通过状态",
                    minWidth: 100,
                    key: "status",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: { color: params.row.status == 0 ? "black" : "red" },
                            },
                            params.row.status == "0" ? "通过" : "未通过"
                        )
                    },
                },
                {
                    title: "姓名",
                    minWidth: 100,
                    key: "userName",
                    align: "center",
                },
                {
                    title: "联系电话",
                    minWidth: 100,
                    key: "account",
                    align: "center",
                },
                {
                    title: "答题时间",
                    minWidth: 100,
                    key: "gmtCreate",
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate && params.row.gmtCreate != '' ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "社区",
                    minWidth: 100,
                    key: "community",
                    align: "center",
                },
                {
                    title: "街道",
                    minWidth: 100,
                    key: "street",
                    align: "center",
                },
            ],
            account: "",
        }
    },
    methods: {
        restData() {
            this.account = ""
            this.page = 1
            this.getList()
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.page = obj.page
            this.getList()
        },
        getList() {
            this.loading = true
            this.$get("/gateway/api/syscenic/pc/question/listQuestionRecordByPage", {
                page: this.page,
                pageSize: this.pageSize,
                topicId: this.$route.query.id,
                dataScopeId: "",
                account: this.account,
                status: "",
            }).then(res => {
                if (res.code == 200 && res.dataList) {
                    this.tableData = res.dataList
                    this.page = res.currentPage
                    this.pageSize = res.pageSize
                    this.total = res.maxCount
                }
                this.$Message.destroy()
                this.loading = false
            })
        },
    },
    async created() {
        this.getList()
    },
}
</script>

<style lang='less' scoped>
.demo-tree-render .ivu-tree-title {
    width: calc(100% - 34px);
    padding: 10px;
    & > span {
        & > span:first-of-type {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 70%;
            display: inline-block;
        }
    }
}
.demo-tree-render .ivu-tree-arrow {
    padding: 10px;
}
.ivu-breadcrumb {
    background-color: #fff;
    line-height: 36px;
    padding: 0 20px;
    margin-bottom: 10px;
    position: relative;
}
.form {
    height: 100%;
    overflow: scroll;
}
.noContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    color: #999;
    font-size: 36px;
}
.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.toolsbarRight {
    display: flex;
    padding-top: 10px;
}
</style>
